import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/reisedurchfall-risikofaktoren"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
                IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627 active-trail">
                      <a
                        href="/reisedurchfall-vorbeugen/"
                        className="active-trail"
                      >
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-auf-reisen"
                  className="block block-menu"
                >
                  <h2>Durchfall auf Reisen</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/reisedurchfall-vorbeugen/" title>
                          Vorbeugung von Reisedurchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/reiseapotheke/" title>
                          Reiseapotheke
                        </a>
                      </li>
                      <li className="leaf active-trail">
                        <a
                          href="/reisedurchfall-risikofaktoren/"
                          title
                          className="active-trail active"
                        >
                          Reisedurchfall Risikofaktoren
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/reisedurchfall-infos-und-fakten/" title>
                          Infos &amp; Fakten zu Reisedurchfall
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Reisedurchfall Risikofaktoren
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="flash_container">
                      <div className="field field-name-field-teaser-stuff field-type-text-long field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                              <img
                                alt
                                src="/sites/default/files/visual_weltkarte1.jpg"
                                style={{
                                  width: "695px",
                                  height: "286px"
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="std-site-alternative">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Einschätzung des Reiserisikos
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            Reisedurchfall ist die häufigste
                            Gesundheitsbeeinträchtigung, von der Reisende
                            geplagt werden. Experten für Reisemedizin schätzen,
                            dass 20 bis 40 Prozent der Reisenden im Urlaub mehr
                            oder weniger stark an Durchfall leiden.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <h2>Kritische Reiseziele</h2>
                              <p>
                                Das Risiko für Reisedurchfall ist nicht überall
                                gleich: Auf der Weltkarte können Sie das
                                Durchfallrisiko der einzelnen Länder auf einem
                                Blick identifizieren. Entsprechend Ihres
                                Reiseziels können Sie Ihre Reiseapotheke
                                vorbereiten.
                              </p>
                              <p>&nbsp;</p>
                              <h2>Länder mit erhöhtem Reisedurchfallrisiko:</h2>
                              <p>
                                Zu den Risikoländern gehören insbesondere Länder
                                in Süd-Amerika, Afrika und Asien. So sollten Sie
                                in Ländern wie Brasilien, Kuba, Ägypten oder
                                Thailand besonders achtsam sein, was Sie zu sich
                                nehmen. Die Regel „Cook it, boil it, peel it or
                                forget it” (Koch es, brat’ es, schäl’ es oder
                                vergiss es), kann Ihnen dabei helfen, das
                                Reisedurchfallrisiko zu minimieren. Zudem
                                sollten Sie besonders bei Trinkwasser vorsichtig
                                sein: Zum Trinken oder Zähneputzen greifen Sie
                                sicherheitshalber auf abgekochtes oder im besten
                                Fall auf abgefülltes Wasser zurück.
                              </p>
                              <p>&nbsp;</p>
                              <h2>Länder mit mittlerem Reisdurchfallrisiko:</h2>
                              <p>
                                Ein mittleres Reisedurchfallrisiko herrscht in
                                Ost- und Südeuropäischen Ländern, Russland sowie
                                in südlichen Teilen Süd-Amerikas und in
                                Süd-Afrika. Auch in diesen Ländern entsprechen
                                die Hygiene- und Trinkwasserqualität meist nicht
                                unseren deutschen Standards. Eine Erkrankung an
                                Reisedurchfall ist deshalb auch in Ländern wie
                                Spanien, Italien, Kroatien genau wie in Chile
                                und Argentinien durchaus möglich.
                              </p>
                              <p>&nbsp;</p>
                              <h2>
                                Länder mit niedrigerem Reisedurchfallrisiko:
                              </h2>
                              <p>
                                Grundsätzlich ist das Reisedurchfallrisiko in
                                den westlichen Industrieländern am niedrigsten.
                                Weniger Sorgen müssen Sie sich also in Ländern
                                in Nord-Amerika, West-Europa sowie in Australien
                                und Neuseeland machen.&nbsp; In den USA, Kanada,
                                Frankreich, Schweden oder Australien herrschen
                                vergleichbare Hygiene- und Trinkwasserstandards
                                wie in Österreich. Aber selbst in diesen Ländern
                                ist immer ein gewisses Reisedurchfallrisiko
                                vorhanden, weshalb es sich lohnt, stets gegen
                                Reisedurchfall gewappnet zu sein.
                              </p>
                              <p>&nbsp;</p>
                              <h2>Kritische Reisezeiten</h2>
                              <p>
                                Achten Sie bei der Planung Ihrer Fernreise auf
                                die Reisezeit. Das Reisdurchfallrisiko kann sich
                                je nach Jahreszeit erhöhen. In Ländern wie
                                Thailand, Indonesien oder Mexiko steigt
                                beispielsweise während der jeweils
                                unterschiedlichen Monsunzeiten das Risiko für
                                akuten Durchfall. Aufgrund hoher Wassermengen
                                und einer oftmals damit einhergehenden
                                Überlastung der Abwassersysteme verschlechtern
                                sich die Hygienestandards dann weiter. In
                                Monaten, in denen es besonders heiß ist, kann
                                das Reisedurchfallrisiko ebenfalls steigen.
                              </p>
                              <p>&nbsp;</p>
                              <h2>Kritische Reisearten</h2>
                              <p>
                                Je nach Art der Reise ist das Risiko, an akutem
                                Durchfall zu erkranken, unterschiedlich hoch.
                                Generell gilt, dass Trekking- und
                                Rucksackurlaube mit einem erhöhten
                                Durchfallrisiko verbunden sind, weil es
                                schwieriger ist, dauerhaft hohe Hygienestandards
                                zu finden, z.B. wenn häufig an Straßenständen
                                gegessen wird.
                              </p>
                              <p>
                                Kreuzfahrten bringen ebenfalls ein erhöhtes
                                Durchfallrisiko mit sich. Aufgrund der Dichte an
                                Menschen haben Durchfallerreger leichtes Spiel,
                                sich auf dem Schiff zu verbreiten.
                              </p>
                              <p>
                                Obwohl das Durchfallrisiko bei Pauschalurlauben
                                tendenziell ein geringeres ist, sollten Sie auch
                                bei dieser Art von Reise vorsichtig sein. Die
                                Hygienestandards können je nach Hotel stark
                                variieren. Häufige Gefahrenquellen sind Buffets,
                                Pool- und Sanitäranalgen.
                              </p>
                              <p>&nbsp;</p>
                              <h2>
                                Imodium® akut Schmelztabletten – die Soforthilfe
                                bei akutem Durchfall:
                              </h2>
                              <p>
                                Die{" "}
                                <a href="/imodium-akut-schmelztabletten/">
                                  Imodium® akut Schmelztabletten
                                </a>{" "}
                                sind für unterwegs oder auf Reisen besonders
                                geeignet, da sie schnell Abhilfe bei akutem
                                Durchfall bieten. Die Schmelztabletten zergehen
                                sofort auf der Zunge und sind ganz einfach
                                ohne Wasser einzunehmen – praktisch und diskret!
                                Dank des angenehmen Minzgeschmacks eignen sie
                                sich auch, wenn ihr Durchfall von Übelkeit
                                begleitet wird. Die{" "}
                                <a href="/imodium-akut-schmelztabletten/">
                                  Imodium® akut Schmelztabletten
                                </a>{" "}
                                sind rezeptfrei in Ihrer Apotheke erhältlich.
                              </p>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf active-trail">
                    <a
                      href="/reisedurchfall-risikofaktoren/"
                      title
                      className="active-trail active"
                    >
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
